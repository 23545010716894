<template>
  <div>
    <!-- <el-card class="box-card"> -->
    <div slot="header" class="clearfix" style="margin-bottom: 20px">
      <el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
      <el-button icon="el-icon-upload2" @click="isupload = true">上传图片</el-button>
      <el-button icon="el-icon-download" @click="downImg">下载</el-button>
      <el-button icon="el-icon-document-copy" @click="copyImg">复制</el-button>
      <el-button icon="el-icon-s-operation" @click="moveImg">移动</el-button>
      <el-button icon="el-icon-delete" @click="delImg()">删除</el-button>
    </div>
    <div class="fileBox" v-if="ImgData.length">
      <div v-for="(item, index) in ImgData" :key="item.id" class="fileBoxitem">
        <div class="Fileimg">
          <img class="fileimg" :src="baseip + item.picPath + item.fileName" alt="" />
        </div>
        <div class="filename">
          <span class="fileTextName" :title="item.name" @dblclick.stop="renName(item)">{{ item.name }}</span>
          <div @click.stop>
            <el-checkbox class="check_img" v-model="checkbox[index]"> </el-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="nullData" style="margin-top: 200px" v-else>暂无数据 <el-button type="text" @click="isupload = true">去上传？</el-button></div>
    <!-- </el-card> -->
    <el-dialog title="上传图片" :visible.sync="isupload" width="405px" :before-close="handleClose">
      <el-upload
        drag
        accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
        action=""
        :file-list="fileList"
        :on-change="changeFile"
        :auto-upload="false"
        :on-remove="handleRemove"
        :multiple="false"
        :limit="2"
      >
        <div style="font-weight: bolder; font-size: 16px; margin-bottom: 20px; margin-top: 40px">拖拽图片到本区域上传</div>
        <div class="el-upload__text" style="margin-bottom: 20px">或者<em>点击这里</em>上传</div>
        <div class="el-upload__tip">图片类型仅限JPG、GIF、BMP、PNG，图片大小不得超过500K</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.stop="uploadFile" size="mini" :disabled="fileList.length === 0 ? true : false">上传</el-button>
        <el-button @click="cloceUploadFile" size="mini">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="50%">
      <div class="dialogName">我的图片库</div>
      <div class="bg">
        <div class="moveBox" v-for="(item, index) in fileData" :key="item.picTypeId" @click="haddleBox(index)">
          <div :class="item.active == true ? 'moveimgActive' : ''" class="moveimg" :style="item.active ? 'border: 1px solid #f39800' : ''">
            <img src="./img/default_files.png" alt="" />
          </div>
          <div class="fileTextName" :title="item.name">{{ item.name }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quitMove()">取 消</el-button>
        <el-button type="primary" @click="MoveOrCopy()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 重命名弹窗 -->
    <el-dialog title="重命名" :visible.sync="isUpdateName" width="30%" :close-on-click-modal="false" append-to-body>
      <el-form>
        <el-form-item label="图片名称">
          <el-col :span="20">
            <el-input v-model="renNamefrom.name"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeUpdateName()">取 消</el-button>
        <el-button type="primary" @click="updateName()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isUpdateName: false,
      radio: '',
      iscopy: false,
      // 移动复制对话框
      dialogVisible: false,
      // 对话框title
      title: '',
      routerdata: '',
      fileData: [],
      // 图片文件
      ImgData: [],
      // 选中
      checkbox: [],
      // 上传fileList
      fileList: [],
      // 上传文件
      isupload: false,
      baseip: '',
      renNamefrom: {
        name: '',
        picId: ''
      }
    };
  },
  mounted() {
    this.baseip = process.env.VUE_APP_ICONIMG_URL;
    this.getFileImg();
    if (this.$route.query.data !== '[object Object]') {
      localStorage.setItem('picTypeId', this.$route.query.data.picTypeId);
      this.routerdata = this.$route.query.data.picTypeId;
    } else {
      this.routerdata = localStorage.getItem('picTypeId');
    }
  },
  methods: {
    async getFileImg() {
      let picTypeId;
      if (this.$route.query.data !== '[object Object]') {
        picTypeId = this.$route.query.data.picTypeId;
      } else {
        picTypeId = localStorage.getItem('picTypeId');
      }
      const imgRes = await this.$apis.imgModel.imgList({ picTypeId });
      if (imgRes) {
        this.ImgData = imgRes;
        this.checkbox.length = this.ImgData.length;
      }
    },
    changeFile(file) {
      this.fileList = [];
      this.fileList.push(file);
    },
    handleRemove() {
      this.fileList = [];
    },
    renName(item) {
      const { name, id } = item;
      this.renNamefrom = {
        name,
        picId: id
      };
      this.isUpdateName = true;
    },
    async updateName() {
      const data = this.renNamefrom;
      const { fail } = await this.$apis.imgModel.updataName(data);
      if (fail) {
        return false;
      }
      this.closeUpdateName();
    },
    closeUpdateName() {
      this.isUpdateName = false;
      this.getFileImg();
    },
    async getFileList() {
      const fileRes = await this.$apis.imgModel.fileList();
      this.fileData = fileRes;
    },
    async uploadFile() {
      const picTypeId = this.routerdata;
      const file = this.fileList[0].raw;
      const { fail } = await this.$apis.imgModel.upload({ picTypeId, file });
      if (fail) {
        return false;
      }
      this.fileList = [];
      this.isupload = false;
      this.getFileImg();
    },
    handleClose() {
      this.fileList = [];
      this.isupload = false;
    },
    downImg() {
      let picIds = '';
      this.checkbox.forEach((item, index) => {
        if (item) {
          if (picIds !== '') {
            picIds = picIds.concat(`,${this.ImgData[index].id}`);
          } else {
            picIds = picIds.concat(this.ImgData[index].id);
          }
        }
      });
      if (picIds === '') {
        this.$message.warning('请选择需要下载的素材');
      } else {
        this.$apis.imgModel.download({ picIds });
      }
    },
    async delImg() {
      let picIds = '';
      this.checkbox.forEach((item, index) => {
        if (item) {
          if (picIds !== '') {
            picIds = picIds.concat(`,${this.ImgData[index].id}`);
          } else {
            picIds = picIds.concat(this.ImgData[index].id);
          }
        }
      });
      if (picIds === '') {
        this.$message.warning('请选择需要删除的素材');
      } else {
        const { fail } = await this.$apis.imgModel.delImg({ picIds });
        if (fail) {
          return false;
        }
      }
      this.getFileImg();
    },
    copyImg() {
      let open = false;
      this.checkbox.forEach(item => {
        if (item) {
          open = true;
        }
      });
      if (open) {
        this.getFileList();
        this.title = '选中复制路径';
        this.iscopy = true;
        this.dialogVisible = true;
      } else {
        this.$message.warning('请选择需要复制的图片');
      }
    },
    moveImg() {
      let open = false;
      this.checkbox.forEach(item => {
        if (item) {
          open = true;
        }
      });
      if (open) {
        this.getFileList();
        this.title = '选中移动路径';
        this.iscopy = false;
        this.dialogVisible = true;
      } else {
        this.$message.warning('请选择需要移动的图片');
      }
    },
    haddleBox(i) {
      this.fileData.forEach(item => {
        item.active = false;
      });
      this.$set(this.fileData[i], 'active', true);
      this.$forceUpdate();
    },
    async MoveOrCopy() {
      let picTypeId;
      this.fileData.forEach(item => {
        if (item.active) {
          picTypeId = item.picTypeId;
        }
      });
      let picIds = '';
      this.checkbox.forEach((item, index) => {
        if (item) {
          if (picIds === '') {
            picIds = `${this.ImgData[index].id}`;
          } else {
            picIds = `${picIds},${this.ImgData[index].id}`;
          }
        }
      });
      if (this.iscopy) {
        const { fail } = await this.$apis.imgModel.copy({ picTypeId, picIds });
        this.quitMove();
        if (fail) {
          return false;
        }
      } else {
        const { fail } = await this.$apis.imgModel.move({ picTypeId, picIds });
        this.quitMove();
        if (fail) {
          return false;
        }
      }
      this.quitMove();
    },
    quitMove() {
      this.fileData = [];
      for (let i = 0; i < this.checkbox.length; i++) {
        this.checkbox[i] = false;
      }
      this.dialogVisible = false;
      this.getFileImg();
    },
    cloceUploadFile() {
      this.fileList = [];
      this.isupload = false;
    }
  }
};
</script>

<style>
.fileBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.fileBoxitem {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  width: 198px;
  height: 198px;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  margin: 10px;
  background-color: #f2f4f3;
  padding: 10px;
}
.fileBoxitem:hover {
  border: 1px solid #f39800;
}
.fileimg {
  width: 98px;
  height: 98px;
}
.Fileimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  border-radius: 3px;
  background-color: #fdffff;
}
.filename {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 40px;
}
.el-checkbox__inner {
  /* border-radius: 50%; */
  width: 18px;
  height: 18px;
}
.el-checkbox__inner::after {
  /* transform: translateX(2px); */
  position: absolute;
  left: 40%;
  top: 20%;
}
.fileTextName {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nullData {
  width: 100%;
  text-align: center;
  color: #a3a3a3;
}
.hide-filebox {
  display: none;
}
.filename {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 40px;
}
.bg {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 400px;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 4px;
}
.moveBox {
  width: 180px;
  height: 180px;
  margin: 22px;
  background: #fff;
}
.moveimg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 154px;
  height: 154px;
  background: #ffffff;
  opacity: 1;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
}
.moveimgActive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 154px;
  height: 154px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #f39800;
  box-shadow: 3px 1px 3px 0px#f39800;
}
.moveimgActive > img {
  width: 98px;
  height: 98px;
}
.moveimg > img {
  width: 98px;
  height: 98px;
}
.el-dialog__header {
  border-bottom: 1px solid #f3f3f3;
}
.moveFileNmae {
  padding: 2px 3px;
}
.dialogName {
  font-size: 18px;
  font-weight: 400;
  color: #a5a5a5;
}
.fileTextName {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
